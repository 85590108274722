//export const URL_API = 'https://reward-cards-system.herokuapp.com/v1/';
//export const URL_API = 'http://rewardcard-env.eba-pzfmv4hm.us-east-2.elasticbeanstalk.com/v1/';
export const URL_API = 'https://api.nubentum.com/v1/';
//export const URL_API = 'https://rewardcard-env.nubentum.com/v1/';
export const URL_LOCAL_API = 'https://rewardcard-env.nubentum.com/v1/';

//ESTATUS
export const STATUS = 'STATUS';

//MENU
export const MENU = 'MENU';

//USERS
export const USERS = 'USERS';
export const LIST_USERS = 'LIST_USERS';
export const GET_USER = 'GET_USER';
export const DELETE_USER = 'DELETE_USER';

//CUSTOMERS
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';

//USERS_ADMIN
export const LIST_USERS_ADMIN = 'LIST_USERS_ADMIN';
export const GET_USER_ADMIN = 'GET_USER_ADMIN';
export const DELETE_USERS_ADMIN = 'DELETE_USERS_ADMIN';

//CUSTOMERS_ADMIN
export const LIST_CUSTOMERS_ADMIN = 'LIST_CUSTOMERS_ADMIN';

//Memberships
export const LIST_MEMBERSHIPS = 'LIST_MEMBERSHIPS';

//PLANES
export const LIST_PLANES_ADMIN = 'LIST_PLANES_ADMIN';
export const GET_PLAN_ADMIN = 'GET_PLAN_ADMIN';

//POINTS
export const GET_POINTS = 'GET_POINTS';
export const GET_PUNTOS_GENERADOS = 'GET_PUNTOS_GENERADOS';

//STATISTICS
export const GET_STATISTICS = 'GET_STATISTICS';
export const GET_STATISTICS_PRODUCTS = 'GET_STATISTICS_PRODUCTS';
export const GET_STATISTICS_PERHOUR = 'GET_STATISTICS_PERHOUR';

//VALUES
export const GET_MAXVALUE = 'GET_MAXVALUE';
export const GET_MINVALUE = 'GET_MINVALUE';
