export function getPuntosGeneradosService(url, data, token) {
  return new Promise((resolve, reject) => {
    var res = fetch(url + 'points/getPointsByDate?' + new URLSearchParams({
      id: data.id,
      fechaInicio: data.firstDate,
      fechaFin: data.lastDate,
    }).toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
        'Authorization': token
      }
    })
      .then(function (response) {
        let status = response.status;
        let res = {ok: response.ok, error: status};
        if(status === 200){
          return response.json();
        } else if(status === 401){
          return res;
        }
      })
      .then(function (responser) {
        return responser;
      }).catch((error) => {
        return error;
      });
    res ? resolve(res) : reject('error-');
  });
}
