import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Nav from './../../menu/navBar';
import SideBar from './../../menu/sideBar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './../style.css';

import { ReactComponent as ExportarIcon } from './../../resources/Exportar-01.svg';

import {
  XYPlot,
  //LineSeries,
  //VerticalGridLines,
  //HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalBarSeries,
  //MarkSeries,
  //GradientDefs,
  //Crosshair,
  //LineMarkSeries,
  //Highlight,
  //ArcSeries,
  LabelSeries
  //ChartLabel
} from 'react-vis';
//import { generateSeededRandom, EXTENDED_DISCRETE_COLOR_RANGE as COLORS } from './showcase-utils';

import {
  getStatisticsAction,
  getStatisticsProductsAction,
  getStatisticsPerHourAction,
  acceptPolicyAction
} from './../actions';
import { statistics, statisticsProducts, statisticsPerHour } from './../selectors';

//import ReactHTMLTableToExcel from 'react-html-table-to-excel';
//import html2canvas from 'html2canvas';
//import { LineChart, Line, CartesianGrid, XAxis as XXAxis, YAxis as YYAxis } from 'recharts';
//import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js';
//import { saveAs } from 'file-saver';
//import { fs } from 'fs';
//import { Chart } from 'react-charts'

import * as htmlToImage from 'html-to-image';

import PuntosGeneradosContainer from './../../pointsMonthExcel/containers/puntosGeneradosContainer';
import PuntosGastadosContainer from './../../pointsMonthExcel/containers/puntosGastadosContainer';
import exportToExcel from './../components/exportToExcel';
import ModalCustom from '../../../globalComponents/modal/modal';
import equal from 'fast-deep-equal';

class HomeContainerAdmin extends Component {
  constructor() {
    super();
    this.state = {
      showpointsP: false,
      showpointsTop: false,
      showpointsHour: false,
      pointsP: [],
      pointsTop: [],
      pointsHour: [],
      pointsP_EXCEL: [],
      pointsTop_EXCEL: [],
      pointsHour_EXCEL: [],
      maxRangeWP: 0,
      maxRangeWTop: 0,
      maxRangeHour: 0,
      imageP: '',
      error: '',
      open: false,
      policies: false,
      policyMessage: ''
    };
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.acceptPolicy = this.acceptPolicy.bind(this);
  }

  componentDidMount() {
    let loginData = JSON.parse(localStorage.getItem('loginData'));
    const token = JSON.parse(localStorage.getItem('token'));
    //loginData.terms_use=false;
    if (loginData) {
      if (loginData.terms_use) {
        this.setState({ policies: true });
        this.props.getStatisticsAction(token, loginData._id).then(
          response => {
            const data = [];
            const dataExcel = [];
            let max = 0;
            if (response.payload) {
              response.payload.forEach(element => {
                if (element.puntosTotal > max) {
                  max = element.puntosTotal;
                }
                data.push({ x: element.expendio, y: element.puntosTotal });
                dataExcel.push({ 'Punto de venta': element.expendio, 'Puntos generados': element.puntosTotal });
              });
              if (response.payload) {
                this.setState({ showpointsP: true, pointsP: data, maxRangeWP: max, pointsP_EXCEL: dataExcel });
              }
            }

            if (response.payload) {
              if (response.payload[0]) {
                if (response.payload[0].error) {
                  if (response.payload[0].error.name === 'TokenExpiredError') {
                    this.props.history.push('/admin/logout/true');
                  }
                }
              }
            }
          }

        );

        this.props.getStatisticsProductsAction(token, loginData._id).then(
          response => {
            const data2 = [];
            const data2Excel = [];
            let max2 = 0;

            if (response.payload) {
              response.payload.forEach(element => {
                if (element.points > max2) {
                  max2 = element.points;
                }
                data2.push({ x: element.name, y: element.points });
                data2Excel.push({ 'Productos': element.name, 'Puntos generados': element.points });
              });
              if (response.payload.length > 0) {
                this.setState({ showpointsTop: true, pointsTop: data2, maxRangeWTop: max2, pointsTop_EXCEL: data2Excel });
              }
            }
            if (response.payload) {
              if (response.payload[0]) {
                if (response.payload[0].error) {
                  if (response.payload[0].error.name === 'TokenExpiredError') {
                    this.props.history.push('/admin/logout/true');
                  }
                }
              }
            }

          }
        );

        this.props.getStatisticsPerHourAction(token, loginData._id).then(
          response => {
            const data3 = [];
            const data3Excel = [];
            let max3 = 0;

            if (response.payload) {
              response.payload.forEach(element => {
                if (element.points > max3) {
                  max3 = element.points;
                }
                data3.push({ x: element.hour + 'hr', y: element.points });
                data3Excel.push({ 'Hora': element.hour + 'hr', 'Puntos generados': element.points });
              });
              if (response.payload.length > 0) {
                this.setState({ showpointsHour: true, pointsHour: data3, maxRangeHour: max3, pointsHour_EXCEL: data3Excel });
              }
            }

            if (response.payload) {
              if (response.payload[0]) {
                if (response.payload[0].error) {
                  if (response.payload[0].error.name === 'TokenExpiredError') {
                    this.props.history.push('/admin/logout/true');
                  }
                }
              }
            }

          }
        );
      } else {
        this.setState({ open: true });
      }
    } else {
      this.props.history.push('/admin/logout/false');
    }

    let pol=<div className="text-justify">
      <p>LICENCIA GENERAL: NUBENTUM.</p>
      <p>Versión 1, Julio 2020</p>
      <p>2020 Copyright (C) Big Pink de Girasolo S.A. de C.V.</p>
      <p>Girasolo S.A. de C.V. RFC: GIR150303JE5, Mariano Jiménez #132 Int. 7-A; Se permite la copia y distribución del presente documento, sin embargo,no está permitida su modificación. </p>
      <p>LICENCIA PÚBLICA GENERAL NUBENTUM CONDICIONES PARA LA COPIA, DISTRIBUCIÓN Y MODIFICACIÓN.</p>
      <ul className="ul-decimal">
        <li>Esta licencia se aplica a cualquier programa u otro trabajo que contenga un aviso colocado por el titular de los derechos de autor diciendo que puede ser distribuido bajo los términos de esta Licencia Pública General.  El &quot;Programa&quot;, abajo, se refiere a cualquier programa o trabajo de este tipo, y un &quot;trabajo basado en el Programa&quot; significa el Programa o cualquier trabajo derivado bajo la ley de derechos de autor: es decir, una obra que contenga el Programa o una parte de él, ya sea al pie de la letra o con modificaciones y/o traducido a otro el lenguaje.  (En adelante, la traducción se incluye sin limitación en el término &quot;modificación&quot;).  Cada licenciatario se dirige como &quot;usted&quot;.</li>
        <li>La duración del presente contrato tendrá duración de un año a partir de la fecha en que se acepte el presente contrato, teniendo uncosto de $ 10,000.00 (Diez mil) pesos mexicanos por punto de venta en el que sea utilizado.</li>
        <li>Usted acepta que el programa recolecte datos que puedan ser utilizados para mejorar los algoritmos o ser utilizados para publicidad, en ningún caso, dichos datos serán vendidos o compartidos con tercero alguno. </li>
        <li>En caso de cancelación de la presente licencia, se deberá realizar durante los primeros 60 (naturales) días de uso del programa si es cancelada dentro de este periodo, se recibirá una bonificación del 50% del restante del contrato, en caso de cancelar después de dicho periodo, no se recibirá bonificación alguna.</li>
        <li>El programa se asegurará de cuidar y respaldar los datos adquiridos por el cliente hasta por un máximo de 2 años.</li>
        <li>En caso de duda, se puede comunicar a <a href="mailto:nubentum@girasolo.com">nubentum@girasolo.com</a> donde uno de nuestros técnicos le ayudará. </li>
      </ul>
    </div>;
    this.setState({policyMessage: pol});

  }

  componentDidUpdate(_prevProps, prevstate) {
    let loginData = JSON.parse(localStorage.getItem('loginData'));
    const token = JSON.parse(localStorage.getItem('token'));
    if (!equal(this.state.policies, prevstate.policies)) {
      if (this.state.policies) {
        this.setState({ policies: true });
        this.props.getStatisticsAction(token, loginData._id).then(
          response => {
            const data = [];
            const dataExcel = [];
            let max = 0;
            if (response.payload) {
              response.payload.forEach(element => {
                if (element.puntosTotal > max) {
                  max = element.puntosTotal;
                }
                data.push({ x: element.expendio, y: element.puntosTotal });
                dataExcel.push({ 'Punto de venta': element.expendio, 'Puntos generados': element.puntosTotal });
              });
              
              if (response.payload) {
                this.setState({ showpointsP: true, pointsP: data, maxRangeWP: max, pointsP_EXCEL: dataExcel });
              }
            }


            if (response.payload) {
              if (response.payload[0]) {
                if (response.payload[0].error) {
                  if (response.payload[0].error.name === 'TokenExpiredError') {
                    this.props.history.push('/admin/logout/true');
                  }
                }
              }
            }
          }

        );

        this.props.getStatisticsProductsAction(token, loginData._id).then(
          response => {
            const data2 = [];
            const data2Excel = [];
            let max2 = 0;

            if (response.payload) {
              response.payload.forEach(element => {
                if (element.points > max2) {
                  max2 = element.points;
                }
                data2.push({ x: element.name, y: element.points });
                data2Excel.push({ 'Productos': element.name, 'Puntos generados': element.points });
              });
              if (response.payload.length > 0) {
                this.setState({ showpointsTop: true, pointsTop: data2, maxRangeWTop: max2, pointsTop_EXCEL: data2Excel });
              }
            }
            if (response.payload) {
              if (response.payload[0]) {
                if (response.payload[0].error) {
                  if (response.payload[0].error.name === 'TokenExpiredError') {
                    this.props.history.push('/admin/logout/true');
                  }
                }
              }
            }

          }
        );

        this.props.getStatisticsPerHourAction(token, loginData._id).then(
          response => {
            const data3 = [];
            const data3Excel = [];
            let max3 = 0;

            if (response.payload) {
              response.payload.forEach(element => {
                if (element.points > max3) {
                  max3 = element.points;
                }
                data3.push({ x: element.hour + 'hr', y: element.points });
                data3Excel.push({ 'Hora': element.hour + 'hr', 'Puntos generados': element.points });
              });
              if (response.payload.length > 0) {
                this.setState({ showpointsHour: true, pointsHour: data3, maxRangeHour: max3, pointsHour_EXCEL: data3Excel });
              }
            }

            if (response.payload) {
              if (response.payload[0]) {
                if (response.payload[0].error) {
                  if (response.payload[0].error.name === 'TokenExpiredError') {
                    this.props.history.push('/admin/logout/true');
                  }
                }
              }
            }

          }
        );
      }
    }
  }

  acceptPolicy(){
    let loginData = JSON.parse(localStorage.getItem('loginData'));
    const token = JSON.parse(localStorage.getItem('token'));
    let terms = {
      terms: JSON.stringify(this.state.policyMessage)
    };

    return acceptPolicyAction(token, loginData._id, terms).then(resp=>{
      if(resp.response.ok){
        this.setState({ open: false, policies: true});
        loginData.terms_use = true;
        localStorage.setItem('loginData', JSON.stringify(loginData));
      }
    });
  }
  onOpenModal() {
    this.setState({ open: true });
  }

  onCloseModal() {
    this.setState({ open: false });
    // eslint-disable-next-line react/prop-types
    this.props.history.push('/admin/logout/false/true');
  }
  handleClick2 = () => {
    /*htmlToImage.toBlob(document.getElementById('svg-chart'))
      .then(function (blob) {
        console.log('blob');
        console.log(blob);

        window.saveAs(blob, 'svg-chart.png');
      });*/

    htmlToImage.toPng(document.getElementById('svg-chart2'))
      .then(function (dataUrl) {
        window.createFormattingWorkbook(dataUrl, 'productos que generan mas puntos');
        return dataUrl;
      }).catch(function (error) {
        this.setState({ error: error });
      });
  };
  handleClick1 = () => {
    htmlToImage.toPng(document.getElementById('svg-chart1'))
      .then(function (dataUrl) {
        window.createFormattingWorkbook(dataUrl, 'puntos generados por punto de venta');
        return dataUrl;
      }).catch(function (error) {
        this.setState({ error: error });
      });
  };
  handleClick3 = () => {
    htmlToImage.toPng(document.getElementById('svg-chart3'))
      .then(function (dataUrl) {
        window.createFormattingWorkbook(dataUrl, 'Puntos generados por hora');
        return dataUrl;
      }).catch(function (error) {
        this.setState({ error: error });
      });
  };

  render() {

    const chartWidth = 950;
    const chartHeight = 800;
    const chartDomain = [0, (this.state.maxRangeWP + 30)];
    const chartDomainTop = [0, (this.state.maxRangeWTop + 30)];
    const chartDomainHour = [0, (this.state.maxRangeHour + 30)];
    const axisStyle = {
      ticks: {
        fontSize: '15px'
      },
      stroke: 'rgb(56, 56, 56)'
    };
    const axisStyleBlack = {
      stroke: 'rgb(56, 56, 56)'
    };

    let today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();
    if(dd<10){
      dd='0'+dd;
    }
    if(mm<10){
      mm='0'+mm;
    }

    today = yyyy+'-'+mm+'-'+dd;
    return (
      <div>
        <ModalCustom
          open={this.state.open}
          close={this.onCloseModal}
          tittle={'TERMINOS Y CONDICIONES DE USO'}
          contentModal={this.state.policyMessage}
          onclickClose={this.onCloseModal}
          onclickAccept={this.acceptPolicy}
          closeText={'DECLINAR'}
          acceptText={'ACEPTAR'}
        />
        {this.state.policies?(
          <div><Nav />
            <div className='row custom-row' id='body-row'>
              <SideBar />
            </div>
            <div className='col-11 float-right m-b-8-planes-SA'>
              <PuntosGeneradosContainer></PuntosGeneradosContainer>
              <PuntosGastadosContainer></PuntosGastadosContainer>
              <div className='row m-t-2'>
                <br />
                <div className='col-md-12'>
                  <div className="row">
                    <div className="col-11 align-self-center span-pointsMonth-SA">
                      <span >Puntos generados por punto de venta</span>
                    </div>
                    <div className='col-md-12'>
                      {this.state.showpointsP ? (
                        <div className='d-inline-block bg-light '>
                          <div key='svg-chart1' id='svg-chart1' className='bg-light'>
                            <p>Puntos generados por punto de venta</p>
                            <XYPlot
                              xType='ordinal'
                              width={chartWidth}
                              height={chartHeight}
                              yDomain={chartDomain}
                              margin={{bottom: 170, left: 70, top: 50}}
                            >
                              <VerticalBarSeries
                                data={this.state.pointsP} barWidth={0.5} color='#006e95' />
                              <XAxis style={axisStyle} tickLabelAngle={-45} />
                              <YAxis style={axisStyleBlack} width={70} />
                              <LabelSeries
                                data={this.state.pointsP.map(obj => {
                                  return { ...obj, label: obj.y.toString() };
                                })}
                                labelAnchorX='middle'
                                labelAnchorY='text-after-edge'
                              />
                            </XYPlot>
                          </div>
                          <button onClick={() => exportToExcel(this.state.pointsP_EXCEL, 'puntos generados por punto de venta')} className='export-btn'><p className='dp-inline '>Exportar a Excel</p><ExportarIcon className='icon-nav inactive-icon-nav dp-inline margin-icon-exp' /></button>
                        </div>
                      ) : ('')}
                    </div>
                  </div>                
                 

                  {this.state.showpointsTop && this.state.pointsTop ? (
                    <div className='d-inline-block bg-light margin-grafics'>
                      <div key='svg-chart2' id='svg-chart2' className='bg-light'>
                        <p >Productos que generan m&aacute;s puntos</p>
                        <XYPlot
                          xType='ordinal'
                          width={chartWidth}
                          height={chartHeight}
                          yDomain={chartDomainTop}
                          margin={{bottom: 250, left: 100}}
                        >
                          <VerticalBarSeries
                            data={this.state.pointsTop} barWidth={0.5} color='#5bc8af' />
                          <XAxis style={axisStyleBlack}  tickLabelAngle={-45}/>
                          <YAxis style={axisStyleBlack} width={70} />
                          <LabelSeries
                            data={this.state.pointsTop.map(obj => {
                              return { ...obj, label: obj.y.toString() };
                            })}
                            labelAnchorX='middle'
                            labelAnchorY='text-after-edge'
                          />
                        </XYPlot>
                      </div>
                      <button onClick={() => exportToExcel(this.state.pointsTop_EXCEL, 'productos que generan mas puntos')} className='export-btn'><p className='dp-inline '>Exportar a Excel</p><ExportarIcon className='icon-nav inactive-icon-nav dp-inline margin-icon-exp' /></button>
                    </div>
                  ) : ('')}

                  {this.state.showpointsHour ? (
                    <div className='d-inline-block bg-light margin-grafics'>
                      <div key='svg-chart3' id='svg-chart3' className='bg-light'>
                        <p>Puntos generados por hora</p>
                        <XYPlot
                          xType='ordinal'
                          width={chartWidth}
                          height={chartHeight}
                          yDomain={chartDomainHour}
                          margin={{bottom: 50, left: 100}}
                        >
                          <VerticalBarSeries
                            data={this.state.pointsHour} barWidth={0.5} color='#006e95' />
                          <XAxis style={axisStyleBlack} tickLabelAngle={-45} />
                          <YAxis style={axisStyleBlack} width={70} />
                          <LabelSeries
                            data={this.state.pointsHour.map(obj => {
                              return { ...obj, label: obj.y.toString() };
                            })}
                            labelAnchorX='middle'
                            labelAnchorY='text-after-edge'
                          />
                        </XYPlot>
                      </div>
                      <button onClick={() => exportToExcel(this.state.pointsHour_EXCEL, 'puntos generados por hora')} className='export-btn'><p className='dp-inline '>Exportar a Excel</p><ExportarIcon className='icon-nav inactive-icon-nav dp-inline margin-icon-exp' /></button>
                    </div>
                  ) : ('')}

                </div>
              </div>
              <div className='row'>
                <div className='col-12 '>
                  <Link to='/export' className='no-border exportar-link float-right'><p className='dp-inline '>Exportar Excel</p><ExportarIcon className='icon-nav inactive-icon-nav dp-inline margin-icon-exp' /></Link>
                </div>
              </div>

              {/*<ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename="tablexls"
            sheet="tablexls"
            buttonText="Download as XLS" />
          <table id="table-to-xls">
            <tr>
              <th>Puntos generados por punto de venta</th>
            </tr>
            <tr>
              <td>
                <img src='http://localhost:3001/assets/login/Fondo%201-04.jpg' className="iconTabla-planes-SA" alt="iconVer" />
              </td>
            </tr>

          </table>
          <br></br>
         <LineChart width={600} height={300} data={data}>
            <Line type="monotone" dataKey="uv" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" />
            <XXAxis dataKey="name" />
            <YYAxis />
          </LineChart>*/}

              <br></br>
              <br></br>
            </div>
          </div>):(<div></div>)}
      </div>
    );
  }
}
HomeContainerAdmin.propTypes = {
  getStatisticsAction: PropTypes.func.isRequired,
  getStatisticsProductsAction: PropTypes.func.isRequired,
  getStatisticsPerHourAction: PropTypes.func.isRequired,
  history: PropTypes.object,
};

HomeContainerAdmin.defaultProps = {
  listStatistics: {},
  listStatisticsProducts: {},
  listStatisticsPerHour: {},

};

const mapStateToProps = state => ({
  listStatistics: statistics(state),
  listStatisticsProducts: statisticsProducts(state),
  listStatisticsPerHour: statisticsPerHour(state),
});

export default withRouter(connect(mapStateToProps, { getStatisticsAction, getStatisticsProductsAction, getStatisticsPerHourAction })(HomeContainerAdmin));
